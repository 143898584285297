import moment from 'moment';
import { useMemo } from 'react';
import { CalculateDaysBetweenDates } from '../../../../helpers/Custom';
import { SystemDetail } from '../../../../models/ApiModels';

interface Props {
  system: SystemDetail;
}
const FunctionTrialsBanner = ({ system }: Props) => {
  const trialDaysLeftUntilExpiration = useMemo(() => {
    if (system.trialExpirationDate) {
      return CalculateDaysBetweenDates(new Date(), system.trialExpirationDate);
    }
    return null;
  }, [system.trialExpirationDate]);

  const daysBetweenLicenseAndTrial = useMemo(() => {
    if (system.licenseInstalledDate && system.trialExpirationDate) {
      return CalculateDaysBetweenDates(system.licenseInstalledDate, system.trialExpirationDate);
    }
    return null;
  }, [system.licenseInstalledDate, system.trialExpirationDate]);

  const handleFunctionColor = () => {
    if (daysBetweenLicenseAndTrial && trialDaysLeftUntilExpiration)
      if (daysBetweenLicenseAndTrial > 10) {
        if (trialDaysLeftUntilExpiration > 20) {
          return 'bg-accent-purple-50';
        } else if (trialDaysLeftUntilExpiration > 10) {
          return 'bg-warning-100';
        } else return 'bg-error-100';
      } else {
        if (trialDaysLeftUntilExpiration > 6) {
          return 'bg-accent-purple-50';
        } else if (trialDaysLeftUntilExpiration > 2) {
          return 'bg-warning-100';
        } else return 'bg-error-100';
      }
  };

  const daysLeftBar =
    trialDaysLeftUntilExpiration && daysBetweenLicenseAndTrial
      ? Math.min((trialDaysLeftUntilExpiration / daysBetweenLicenseAndTrial) * 100, 100)
      : 0;

  return (
    <>
      {system.licenseInstalledDate && (
        <div className='lg:mt-11 xl:mt-0'>
          <h4 className='prose-heading4'>Function trial</h4>
          <p className='prose-paragraphSmaller border-b border-gray-10 pb-2'>
            This system is having a temporary Dynamic Plus + Underwater lights license. After the trial, the system will
            reset to the functions it had before.
          </p>
          <div className='relative w-full bg-gray-5'>
            <span className='absolute right-2 top-1/2 -translate-y-1/2 opacity-50 prose-labelPill'>
              {system.status === 'Pending License' && 'Waiting for installation.'} {trialDaysLeftUntilExpiration} days
              left ({moment(system.trialExpirationDate).format('YYYY-MM-DD')})
            </span>
            <div
              style={{ width: daysLeftBar + '%' }}
              className={` ${handleFunctionColor()} w-full h-10  p-2 mt-5`}
            ></div>
          </div>
        </div>
      )}
    </>
  );
};

export default FunctionTrialsBanner;
