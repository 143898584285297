import { useMsal } from '@azure/msal-react';
import { faEdit, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { InvertButton, Popup, PrimaryButton, StatusButton } from 'component-library';
import _ from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { SystemDetail, User } from '../../../models/ApiModels';
import Error from '../../../routes/PageNotFound';
import { AddComment, UpdateSystem } from '../../../services/SystemService';
import { GetUser } from '../../../services/UserService';
import { ActionType } from '../../../store/actionTypes';
import useRequest from '../../../utils/net/useRequest';
import PermissionGate, { ROLES } from '../../../utils/PermissionGate';
import ExtendWarrantyModal from './ExtendWarrantyModal';
import FunctionTrialsBanner from './FunctionTrials/FunctionTrialsBanner';
import OrderConfirmation from './OrderConfirmation';
import SystemDetailsBoatInfo from './SystemDetailsBoatInfo';
import SystemDetailsComments from './SystemDetailsComments';
import SystemDetailsHardware from './SystemDetailsHardware';
import SystemDetailsInfo from './SystemDetailsInfo';
import SystemDetailsSoftware from './SystemDetailsSoftware';
import SystemDetailsUpgradeLicense from './SystemDetailsUpgradeLicense/SystemDetailsUpgradeLicense';

function SystemDetails() {
  const [
    system,
    setSystem,
    oldSystem,
    GetSystem,
    setLoading,
    loading,
    currentSelectedOem,
    hasChanged,
    lightningUnits,
    groups,
    leavingPage,
    setLeavingPage,
    openPopup,
    setOpenPopup,
    showUpgradeLicense,
    setShowUpgradeLicense,
    showOrderConfirmation,
    setShowOrderConfirmation,
  ] = useOutletContext<any>();
  const { accounts } = useMsal();
  const account = accounts[0];
  const [currentUser = {}] = useRequest<User>(
    () => GetUser(account?.idTokenClaims?.extension_oemUserId as string),
    [account]
  );
  const [edit, setEdit] = useState(false);
  const [openLicensePopup, setOpenLicensePopup] = useState(false);
  const [openWarrantyModal, setOpenWarrantyModal] = useState(false);
  const [savingComment, setSavingComment] = useState(false);
  const topRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const licenseChanged = useMemo(() => {
    return (
      !_.isEqual(system?.packageId, oldSystem?.packageId) ||
      !_.isEqual(system?.addonFeatureIds, oldSystem?.addonFeatureIds)
    );
  }, [system]);

  function DiscardChanges() {
    if (leavingPage) {
      setTimeout(() => {
        navigate(-1);
      }, 1);
    }
    if (oldSystem) setSystem({ ...oldSystem });
    setOpenPopup(false);
    setEdit(false);
  }

  function leaveEdit() {
    if (hasChanged) {
      setOpenPopup(true);
    } else {
      setEdit(!edit);
    }
  }

  async function UpdateSystemWithComments(comment: string) {
    if (system !== undefined && comment !== undefined) {
      setSavingComment(true);
      AddComment(system, comment)
        .then(() => {
          GetSystem();
          setSavingComment(false);
        })
        .catch(() => {
          setSavingComment(false);
        });
    }
  }

  const updateSystemAndClose = useCallback((updatedSystem: SystemDetail) => {
    setLoading(true);
    UpdateSystem(updatedSystem)
      .then(() => {
        dispatch({
          type: ActionType.SET_SNACKBAR,
          payload: { heading: 'Successfully updated system!', status: 'success' },
        });
        setTimeout(() => {
          setEdit(false);
          setOpenLicensePopup(false);
          GetSystem();
        }, 1);
      })
      .catch(() => {
        console.log('Something went wrong!');
        setOpenLicensePopup(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div ref={topRef}>
      {system ? (
        <>
          <OrderConfirmation
            showOrderConfirmation={showOrderConfirmation}
            setShowOrderConfirmation={() => setShowOrderConfirmation(false)}
            currentOem={currentSelectedOem}
          />
          {showUpgradeLicense ? (
            <SystemDetailsUpgradeLicense
              setShowUpgradeLicense={(value: boolean, didUpgrade: boolean) => {
                setShowOrderConfirmation(didUpgrade);
                setShowUpgradeLicense(value);
                if (didUpgrade) GetSystem();
              }}
              system={system}
              topRef={topRef}
            />
          ) : (
            <div className='flex flex-col'>
              <PermissionGate roles={[ROLES.admin, ROLES.editor, ROLES.oemAdmin, ROLES.oemEditor]} rejected={undefined}>
                <div className='w-full lg:ml-auto lg:w-fit flex flex-col gap-2 flex-wrap md:flex-nowrap md:flex-row lg:sticky top-0 right-0 z-10 my-6 lg:mt-11 lg:-mb-11 xl:mb-12 xl:-mt-12'>
                  {!edit ? (
                    <>
                      {system.status === 'Installing' && (
                        <StatusButton
                          status='success'
                          label='Extend warranty'
                          icon={faPlus}
                          onClick={() => setOpenWarrantyModal(true)}
                          fullWidth
                        />
                      )}
                      <PrimaryButton
                        label={'Edit system details'}
                        icon={faEdit}
                        onClick={() => setEdit(true)}
                        disabled={loading}
                        fullWidth
                      />
                    </>
                  ) : (
                    <>
                      <div className='w-full bg-white'>
                        <InvertButton
                          label={'Leave edit'}
                          onClick={() => {
                            setLeavingPage(false);
                            leaveEdit();
                          }}
                          fullWidth
                        />
                      </div>
                      <PrimaryButton
                        label={'Save system'}
                        disabled={!hasChanged}
                        onClick={() => {
                          if (licenseChanged) setOpenLicensePopup(true);
                          else updateSystemAndClose(system);
                        }}
                        fullWidth
                      />
                    </>
                  )}
                </div>
              </PermissionGate>
              <div className='flex flex-col gap-16 pt-12 md:pt-2'>
                {system.trialLicense && <FunctionTrialsBanner system={system} />}
                <SystemDetailsInfo
                  editMode={edit}
                  system={system}
                  setSystem={(updatedSystem: SystemDetail) => setSystem({ ...updatedSystem })}
                />
                <SystemDetailsBoatInfo
                  editMode={edit}
                  system={system}
                  currentOem={currentSelectedOem}
                  setSystem={(updatedSystem: SystemDetail) => setSystem({ ...updatedSystem })}
                />
                <SystemDetailsSoftware
                  editMode={edit}
                  system={system}
                  setSystem={(system: SystemDetail) => setSystem({ ...system })}
                  showUpgradeLicense={showUpgradeLicense}
                  setShowUpgradeLicense={(value: boolean) => setShowUpgradeLicense(value)}
                  topRef={topRef}
                />
                <SystemDetailsHardware
                  editMode={edit}
                  system={system}
                  setSystem={(system: SystemDetail) => setSystem({ ...system })}
                  softwareGroups={groups}
                  lightningUnits={lightningUnits}
                />
                <SystemDetailsComments
                  editMode={edit}
                  system={system}
                  updateSystemWithComments={(comment: string) => UpdateSystemWithComments(comment)}
                  savingComment={savingComment}
                  setSystem={(system: SystemDetail) => setSystem(system)}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        system === undefined && <Error />
      )}
      {openPopup && (
        <Popup
          close={() => setOpenPopup(false)}
          confirm={DiscardChanges}
          heading='Discard system changes?'
          paragraph='You will lose all the changes made to this system.'
          status='error'
          cancelText='Keep editing'
          confirmText='Discard changes'
        />
      )}
      {openLicensePopup && (
        <Popup
          close={() => setOpenLicensePopup(false)}
          confirm={() => system && updateSystemAndClose(system)}
          heading='Override license?'
          paragraph='A new license will be created with your new selection. The systems new license will immediately be available for the hardware system to download. The customer will not get an invoice. If you want to upgrade the system, and generate an invoice, use the upgrade system feature instead.'
          status='error'
          cancelText='Keep editing'
          confirmText='Override license'
        />
      )}
      {openWarrantyModal && (
        <ExtendWarrantyModal system={system} user={currentUser} close={() => setOpenWarrantyModal(false)} />
      )}
    </div>
  );
}

export default SystemDetails;
