import { StatusPill } from 'component-library';
import moment from 'moment';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { Oem, OrderDetails, PaymentTerm } from '../../models/ApiModels';
import { handleOrderTypeText } from './OrderDetails';

interface Props {
  order: OrderDetails;
}

export const OrderDetailsInformation = ({ order }: Props) => {
  const { isHumphreeUser, currentOem } = useContext(AppContext);
  const oems = useSelector((state: any) => state.oems.data);
  const paymentTerms = useSelector((state: any) => state.configurations.paymentTerms);
  const paymentTerm = paymentTerms.find((paymentTerm: PaymentTerm) => paymentTerm.id === order.paymentTermsId)?.name;

  return (
    <div>
      <span className='prose-heading4'>Order information</span>
      <div className='border-b border-gray-10 mt-4'></div>
      <div className='border-b border-gray-10 flex justify-between items-center py-5'>
        <span>Customer</span>
        <p className='flex items-center prose-paragraphBase'>
          {oems.find((oem: Oem) => oem.id === order.oemTenantId)?.name || currentOem?.name}
        </p>
      </div>
      <div className='border-b border-gray-10 flex justify-between items-center py-5'>
        <span>Order date</span>
        <p className='flex items-center prose-paragraphBase'>{moment(order.orderDate).format('YYYY-MM-DD, HH:mm')}</p>
      </div>
      <div className='border-b border-gray-10 flex justify-between items-center py-5'>
        <span>Order type</span>
        <div className='flex items-center prose-paragraphBase'>
          <StatusPill color={'Light Gray'} text={handleOrderTypeText(order.type)} />
        </div>
      </div>
      <div className='border-b border-gray-10 flex justify-between items-center py-5'>
        <span>External document number</span>
        <p className='flex items-center prose-paragraphBase'>{order.externalDocumentNumber}</p>
      </div>
      <div className='border-b border-gray-10 flex justify-between items-center py-5'>
        <span>Humphree order number</span>
        {isHumphreeUser ? (
          <a
            target='_blank'
            rel='noreferrer'
            className='text-primary-100 prose-paragraphBase underline'
            href={`https://businesscentral.dynamics.com/Humphree/?company=Humphree%20AB&page=9305&filter='No.'%20IS%20'${order.orderNumber}'`}
          >
            {order.orderNumber}
          </a>
        ) : (
          <p className='flex items-center prose-paragraphBase'>{order.orderNumber}</p>
        )}
      </div>
      <div className='border-b border-gray-10 flex justify-between items-center py-5'>
        <span>Humphree system</span>
        <Link to={'/systems/' + order.humphreeSystemId}>
          <p className='flex items-center text-primary-100 prose-paragraphBase underline'>{order.humphreeSystemId}</p>
        </Link>
      </div>
      <div className='border-b border-gray-10 flex justify-between items-center py-5'>
        <span>Payment terms</span>
        <p className='flex items-center prose-paragraphBase'>{paymentTerm}</p>
      </div>
    </div>
  );
};
