import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import { ChangeEvent, useMemo, useState } from 'react';
import { SystemComment, User } from '../../../models/ApiModels';
import PermissionsGate, { ROLES } from '../../../utils/PermissionGate';

interface Props {
  comment: SystemComment;
  deleteComment: () => void;
  updateComment: (comment: SystemComment) => void;
  user: User;
  humphreeUser: any;
  isHumphreeUser: boolean;
  editMode: boolean;
}

export default function SystemDetailsCommentItem(props: Props) {
  const { accounts } = useMsal();
  const account = accounts[0];
  const isOwnerOfComment = useMemo(() => {
    return account?.idTokenClaims?.extension_oemUserId === props.comment.userId;
  }, [props.user]);
  const [comment, setComment] = useState(props.comment);
  const [editComment, setEditComment] = useState(false);

  return (
    <div className='flex justify-between pt-5 pb-3 border-b border-gray-10'>
      <span className='prose-paragraphSmaller flex flex-col w-4/5'>
        <div className='flex gap-1 mb-3'>
          <span className='prose-mainMenuL2ItemActive text-primary-100'>
            {' '}
            {props.user?.displayName === undefined ? props.humphreeUser : props.user.displayName}{' '}
          </span>
          {props.user !== undefined && (
            <span className='prose-mainMenuL2ItemActive text-primary-100'> {props.isHumphreeUser && '(Humphree)'}</span>
          )}
        </div>

        {editComment ? (
          <textarea
            className='bg-gray-3 max-w-[460px] min-h-[110px] resize-none p-2 prose-paragraphSmaller focus:ring-1 focus:ring-primary-100 focus:border-transparent outline-none'
            placeholder='Add comment...'
            onBlur={() => {
              props.updateComment(comment);
              setEditComment(false);
            }}
            value={comment.text}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
              comment.text = event.target.value;
              setComment({ ...comment });
            }}
          ></textarea>
        ) : (
          <span>{props.comment.text}</span>
        )}
      </span>
      <div className='flex flex-col whitespace-nowrap justify-start items-end'>
        <span className='prose-paragraphBase'>{moment(props.comment.createdDate).format('MMMM D, YYYY, LT')}</span>
        <PermissionsGate roles={[ROLES.admin]} permissionException={isOwnerOfComment}>
          <div className='flex flex-col items-end justify-end gap-4'>
            {!editComment && (
              <span
                className='underline text-primary-80 font-semibold cursor-pointer'
                onClick={() => setEditComment(true)}
              >
                Edit
              </span>
            )}
            <span
              className='underline text-error-100 font-semibold cursor-pointer'
              onClick={() => props.deleteComment()}
            >
              Delete
            </span>
          </div>
        </PermissionsGate>
      </div>
    </div>
  );
}
