import { Input, Tooltip } from 'component-library';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import { HardwareSystem, Item, SoftwareGroup, SystemDetail } from '../../../models/ApiModels';
import PermissionsGate, { ROLES } from '../../../utils/PermissionGate';
import SystemDetailsHardwareControlPanel from './SystemDetailsHardwareControlPanel';
import SystemDetailsHardwareControlUnit from './SystemDetailsHardwareControlUnit';
import SystemDetailsHardwareInterceptors from './SystemDetailsHardwareInterceptors';

interface Props {
  system: SystemDetail;
  setSystem: (system: SystemDetail) => void;
  editMode?: boolean;
  softwareGroups: SoftwareGroup[];
  lightningUnits: HardwareSystem[];
}

function SystemDetailsHardware(props: Props) {
  const { isHumphreeUser } = useContext(AppContext);
  const items: Item[] = useSelector((state: any) => state.configurations.items);

  const displayName = items.find(
    (item: Item) => props.system.hardwareKitItemNumber?.slice(0, 6) === item.articleNumber
  )?.displayName;

  return (
    <div className='flex flex-col'>
      <h1 className='prose-heading4 border-b border-gray-10 pb-3'>Hardware units</h1>

      <div className='border-b border-gray-10 flex-row flex items-center justify-between py-5'>
        <div className='flex flex-row gap-2 items-center'>
          <span>Software group</span>
          <Tooltip
            description='This system is assigned to a special software group, and will receive special software versions.'
            darkmode
          />
        </div>
        {props.system.group?.groupName ? (
          <PermissionsGate roles={[ROLES.softwareManager]} rejected={<p>{props.system.group.groupName}</p>}>
            <span className='prose-paragraphBase text-gray-100'>
              <Link
                to={'/software/group/' + props.system.lightningHardwareSystem?.groupId}
                className='whitespace-nowrap cursor-pointer underline text-primary-100 hover:text-primary-200'
              >
                {props.system.group?.groupName}
              </Link>
            </span>
          </PermissionsGate>
        ) : (
          <span className='prose-paragraphBase italic text-gray-40'>Not assigned</span>
        )}
      </div>
      {props.editMode && isHumphreeUser ? (
        <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center'>
          <h2 className='prose-paragraphBase mb-1'>
            <span className='flex gap-2'>Kit part number</span>
          </h2>
          <div className='w-[250px]'>
            <Input
              value={props.system.hardwareKitItemNumber || ''}
              placeholder='Enter kit part number...'
              onChange={(input: string) => {
                props.system.hardwareKitItemNumber = input;
                props.setSystem({ ...props.system });
              }}
            />
          </div>
        </div>
      ) : (
        props.system.hardwareKitItemNumber && (
          <div className='border-b border-gray-10 flex-row flex items-center justify-between py-5'>
            <h2 className='flex flex-row gap-2 items-center'>
              <span>Kit part number</span>
            </h2>
            <p className='flex items-center prose-paragraphBase'>
              {props.system?.hardwareKitItemNumber} {displayName && '- ' + displayName}
            </p>
          </div>
        )
      )}
      <SystemDetailsHardwareControlUnit system={props.system} />
      {props.system.status === 'Installing' ||
      props.system.status === 'Active' ||
      props.system.status === 'PendingLicense' ? (
        <>
          <SystemDetailsHardwareControlPanel system={props.system} />
          <SystemDetailsHardwareInterceptors system={props.system} />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default SystemDetailsHardware;
