import { faCalendar, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { Input, InvertButton, PrimaryButton, RadioButton, StatusButton } from 'component-library';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppContext } from '../../../../contexts/AppContext';
import { SystemDetail } from '../../../../models/ApiModels';
import { StartFunctionTrial } from '../../../../services/SystemService';
import { ActionType } from '../../../../store/actionTypes';

interface Props {
  setOpenFunctionTrialPopup: (value: boolean) => void;
  system: SystemDetail;
}

const FunctionTrials = ({ setOpenFunctionTrialPopup, system }: Props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const initFunctionTrial = { trialLimit: system.trialLimit, customTrial: false };
  const [functionTrial, setFunctionTrial] = useState<{ trialLimit: any; customTrial: boolean }>(initFunctionTrial);
  const { isHumphreeUser } = useContext(AppContext);
  const [customDuration, setCustomDuration] = useState(false);
  const [customDays, setCustomDays] = useState<number>();

  const handleDaysChange = (e: number) => {
    setCustomDays(e);
    setFunctionTrial({ trialLimit: e * 1440, customTrial: true });
  };

  async function FunctionTrial() {
    setLoading(true);
    if (system.id) {
      try {
        await StartFunctionTrial(system.id, system.oemId, functionTrial);
      } catch (error: any) {
        dispatch({
          type: ActionType.SET_SNACKBAR,
          payload: { heading: error.message, status: 'error' },
        });
      } finally {
        dispatch({
          type: ActionType.SET_SNACKBAR,
          payload: { heading: 'Trial activated', status: 'success' },
        });
        setLoading(false);
        setOpenFunctionTrialPopup(false);
      }
    }
  }

  return (
    <div className='fixed z-50 top-0 left-0 bg-black bg-opacity-50 w-full h-full'>
      <div className='flex items-center justify-center h-full'>
        <div className='grid gap-4 relative m-2 p-4 md:p-8 bg-white w-full max-w-xl md:w-fit shadow-popup popup'>
          {!customDuration && system.trialRemainingDays !== undefined ? (
            <>
              <h3 className='prose-heading3'>Activate function trials</h3>
              <span className='flex gap-2'>
                <RadioButton
                  active={functionTrial.trialLimit === 14400}
                  onClick={() => {
                    if (system.trialRemainingDays && system.trialRemainingDays >= 14400)
                      setFunctionTrial({ trialLimit: 14400, customTrial: false });
                  }}
                  disabled={system.trialRemainingDays < 14400}
                />
                10 day trial
              </span>
              <span className='flex gap-2'>
                <RadioButton
                  active={functionTrial.trialLimit === 43200}
                  onClick={() => {
                    if (system.trialRemainingDays && system?.trialRemainingDays >= 43200) {
                      setFunctionTrial({ trialLimit: 43200, customTrial: false });
                    }
                  }}
                  disabled={system.trialRemainingDays < 43200}
                />
                30 day trial
              </span>
              <p>
                The trial will start when it’s installed in the system. With 10 days remaining, you will receive a
                reminder about the test so you can reach out to the boat owner.
              </p>
              <p>
                After the trial period, the function package will go back to the original package. You can at any time
                purchase an upgrade for the system.
              </p>
            </>
          ) : (
            <>
              <h3 className='prose-heading3'>Custom duration trial</h3>
              <Input
                label='Enter amount of trial days...' //only for dev
                value={customDays}
                icon={faCalendar}
                onChange={(days: number) => handleDaysChange(days)}
                placeholder='Enter amount of trial days...'
              />
              <Input
                label='Enter amount of trial minutes...'
                value={functionTrial.trialLimit || ''}
                icon={faCalendar}
                onChange={(minutes: number) => {
                  functionTrial.trialLimit = minutes;
                  functionTrial.customTrial = true;
                  setFunctionTrial({ ...functionTrial });
                }}
                placeholder='Enter amount of trial minutes...'
              />
              <p>This trial will not consume trial days and can only be started because you are a Humphree user.</p>
              <p>
                The trial will start when it’s installed in the system. With 10 days remaining, you will receive a
                reminder about the test so you can reach out to the boat owner.
              </p>
              <p>
                After the test period, the function package will go back to the original package. You can at any time
                purchase an upgrade for the system. Do not hand out trials for 100 years.
              </p>
            </>
          )}
          <h6 className='prose-buttonStandard'>Dynamic plus + underwater lights</h6>
          <div className='flex gap-5 justify-end flex-wrap'>
            <InvertButton label='Cancel' onClick={() => setOpenFunctionTrialPopup(false)} />
            {isHumphreeUser && (
              <PrimaryButton
                icon={faCalendar}
                label={customDuration ? 'Go back' : 'Custom duration'}
                onClick={() => {
                  setCustomDuration(!customDuration);
                  setFunctionTrial({ trialLimit: 0, customTrial: customDuration ? true : false });
                }}
              />
            )}
            <StatusButton
              disabled={functionTrial.trialLimit === 0}
              icon={faChevronRight}
              label='Activate trial'
              onClick={() => FunctionTrial()}
              status='success'
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FunctionTrials;
