import { faArrowRight, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, PrimaryButton } from 'component-library';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { HardwareSystem, LogEntry, SoftwareGroup } from '../../../models/ApiModels';
import { GetSoftwareGroups } from '../../../services/SoftwareUpdateService';
import { GetLHSSerialNumberAsync, UpdateHardwareSystemAsync } from '../../../services/UnitService';
import { ActionType } from '../../../store/actionTypes';
import useRequest from '../../../utils/net/useRequest';
import HardwareUnitsDetailsLog from './HardwareUnitsDetailsLog';

function HardwareUnitsDetailsLHS() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hardwareSystem, setHardwareSystem] = useState<HardwareSystem>();
  const { serial } = useParams();
  const [lhs, lhsLoading, lhsError] = useRequest<HardwareSystem>(() => {
    if (serial !== undefined) return GetLHSSerialNumberAsync(serial);
  });
  const [groups, groupLoading, groupError] = useRequest<SoftwareGroup[]>(() => {
    if (serial !== undefined) return GetSoftwareGroups();
  });

  const noGroupInDropdown = { id: '0', value: 'None' };

  useEffect(() => {
    if (lhsError) navigate(-1);
  }, [lhsError]);

  const UpdateHardwareSystems = useCallback(() => {
    if (hardwareSystem) {
      UpdateHardwareSystemAsync(hardwareSystem).then((response: HardwareSystem) => {
        setHardwareSystem({ ...response });
        if (response.groupId === '0') {
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: 'Successfully removed group!', status: 'success' },
          });
        } else
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: 'Successfully added group!', status: 'success' },
          });
      });
    }
  }, [hardwareSystem]);

  useEffect(() => {
    setHardwareSystem(lhs);
  }, [lhs]);

  return (
    <div className='max-w-[2000px]'>
      {!lhsError ? (
        <div>
          {lhsLoading || groupLoading ? (
            <div className='flex items-center flex-col gap-4 h-96 justify-center'>
              <span className='text-primary-400 prose-heading5'>Loading system...</span>
              <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
            </div>
          ) : (
            <div>
              <div className='md:prose-heading3 2xl:prose-heading3 items-center gap-1 mb-14'>
                Lightning Hardware system
                <FontAwesomeIcon icon={faChevronRight} className='mx-2' size='xs' />
                {serial}
              </div>
              <div className='prose-heading4 pb-5 border-b border-gray-10'>General information</div>
              <div className='flex py-5 justify-between border-b border-gray-10'>
                <div>Humphree ID</div>
                {hardwareSystem?.connectedSystemId ? (
                  <div className='flex flex-col text-right'>
                    <div>{hardwareSystem?.connectedSystemId}</div>
                    <div className='flex justify-end pt-10 items-center prose-buttonStandard'>
                      <Link to={`../systems/${hardwareSystem.connectedSystemId}`} className='cursor-pointer'>
                        Go to system details view
                      </Link>
                      <FontAwesomeIcon icon={faArrowRight} className='ml-3' size='lg' />
                    </div>
                  </div>
                ) : (
                  <div className='italic text-gray-40 prose-paragraphBase'>Not assigned</div>
                )}
              </div>
              <div className='flex py-5 justify-between border-b border-gray-10'>
                <div>Created date</div>
                <div>
                  {hardwareSystem?.logEntries.length !== 0 ? (
                    moment(
                      hardwareSystem?.logEntries?.find(
                        (date: LogEntry) =>
                          date.message === 'Created' || `Connected system to ${hardwareSystem.connectedSystemId}` //Checks the message for ID or Created.
                      )?.time
                    ).format('MMMM D, YYYY, LT')
                  ) : (
                    <div className='italic text-gray-40 prose-paragraphBase'>Not assigned</div> //Might not be needed since created date should be in array.
                  )}
                </div>
              </div>
              {groups?.length > 0 && hardwareSystem && (
                <div className='flex py-5 justify-between items-center border-b border-gray-10'>
                  <div>Software group</div>
                  <div>
                    <Dropdown
                      size='Medium'
                      label=''
                      value={groups.find((group: SoftwareGroup) => group.id === hardwareSystem.groupId)?.groupName}
                      placeholder='No group connected'
                      optionsData={[
                        noGroupInDropdown,
                        ...groups.map((group: SoftwareGroup) => {
                          return { id: group.id, value: group.groupName };
                        }),
                      ]}
                      onValueChosen={async (value: any) => {
                        if (value === '0') {
                          hardwareSystem.groupId = null;
                          setHardwareSystem({ ...hardwareSystem });
                        } else hardwareSystem.groupId = value;
                        setHardwareSystem({ ...hardwareSystem });
                        UpdateHardwareSystems();
                      }}
                    />
                  </div>
                </div>
              )}
              <div className='mt-14'>
                <div className='prose-heading4 pb-2'>Individual Lightning units</div>
                <div className='prose-paragraphSmaller pb-5 mb-10 border-b border-gray-10 text-gray-60 '>
                  These were the last reported units connected to this Lightning hardware system
                </div>
                <div className='w-full lg:flex flex-wrap justify-between gap-2'>
                  <div>
                    <div className='prose-heading5'>Lightning Control Panel(s)</div>
                    <div className='flex flex-col mt-5'>
                      {hardwareSystem?.primaryControlPanelSerial && (
                        <>
                          <div className='prose-heading6'>LCP Primary</div>
                          <div className='2xl:gap-7 xl:flex items-center gap-4 mb-5 justify-between'>
                            Serial no.{' '}
                            <span className='font-code'>{hardwareSystem.primaryControlPanelSerial.toString()}</span>
                            <PrimaryButton
                              label={'View unit log'}
                              onClick={() => navigate('/units/lcp/' + hardwareSystem.primaryControlPanelSerial)}
                            />
                          </div>
                        </>
                      )}
                      {hardwareSystem?.secondaryControlPanelSerial && (
                        <>
                          <div className='prose-heading6'>LCP Secondary</div>
                          <div className='2xl:gap-7 xl:flex items-center gap-4 mb-5 justify-between'>
                            Serial no.{' '}
                            <span className='font-code'>{hardwareSystem.secondaryControlPanelSerial.toString()}</span>
                            <PrimaryButton
                              label={'View unit log'}
                              onClick={() => navigate('/units/lcp/' + hardwareSystem.secondaryControlPanelSerial)}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className='prose-heading5'>Lightning Control Unit</div>
                    <div className='flex flex-col mt-5'>
                      {hardwareSystem?.controlUnitSerial && (
                        <>
                          <div className='prose-heading6'>LCU</div>
                          <div className='2xl:gap-7 xl:flex items-center gap-4 mb-5 justify-between'>
                            Serial no. <span className='font-code'>{hardwareSystem.controlUnitSerial.toString()}</span>
                            <PrimaryButton
                              label={'View unit log'}
                              onClick={() => navigate('/units/lcu/' + hardwareSystem.controlUnitSerial)}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className='prose-heading5'>Lightning Servo Units</div>
                    <div className='flex flex-col mt-5'>
                      {hardwareSystem?.starboardServoSerial && (
                        <>
                          <div className='prose-heading6'>LSU Starboard</div>
                          <div className='2xl:gap-7 xl:flex items-center gap-4 mb-5 justify-between'>
                            Serial no.{' '}
                            <span className='font-code'>{hardwareSystem.starboardServoSerial.toString()}</span>
                            <PrimaryButton
                              label={'View unit log'}
                              onClick={() => navigate('/units/lsu/' + hardwareSystem.starboardServoSerial)}
                            />
                          </div>
                        </>
                      )}
                      {hardwareSystem?.portServoSerial && (
                        <>
                          <div className='prose-heading6'>LSU Port</div>
                          <div className='2xl:gap-7 xl:flex items-center gap-4 mb-5 justify-between'>
                            Serial no. <span className='font-code'>{hardwareSystem.portServoSerial.toString()}</span>
                            <PrimaryButton
                              label={'View unit log'}
                              onClick={() => navigate('/units/lsu/' + hardwareSystem.portServoSerial)}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <HardwareUnitsDetailsLog lhs={hardwareSystem} />
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default HardwareUnitsDetailsLHS;
