import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { Oem } from '../../models/ApiModels';
import { SettingsTabs } from './SettingsTabs';

export function Settings() {
  const [currentTab, setCurrentTab] = useState('Administration');
  const { currentOem, currentOemTenantId, representingOem, isBusinessCustomer, isHumphreeUser } =
    useContext(AppContext);
  const oems = useSelector((state: any) => state.oems.data);
  const selectedOem = oems.find((oem: Oem) => oem.id === currentOemTenantId)?.name;
  const parentOem = oems.find((oem: Oem) => oem.id === representingOem?.parentOEMTenantId);

  return (
    <div className='max-w-[2000px]'>
      <div className='flex items-center gap-4 prose-heading3 text-primary-400 mb-20'>
        {/* showing parent in breadcrumb (only shows for humphree users) */}
        {parentOem && representingOem && (
          <>
            <span>{parentOem?.name}</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </>
        )}
        {currentOemTenantId === '12345678-1234-1234-1234-123456789000' ? 'Humphree' : selectedOem || currentOem?.name}{' '}
        settings
      </div>
      <SettingsTabs currentTab={currentTab} changeTab={(value: string) => setCurrentTab(value)} />
      <Outlet />
    </div>
  );
}
